import { HttpClient ,HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable,throwError} from 'rxjs'
import { catchError, last, map, tap } from 'rxjs/operators';

const baseApiUrl = '';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
}

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private http:HttpClient) { }

  getdashBoardDataById(Id : any): Observable<any> {
    const get_url = baseApiUrl + 'subscriber/getBasicCustomerDetails/' +Id;
  
    return this.http.get<any>(get_url,
      { headers: httpOptions.headers }).pipe(
        map(res => res 
          ),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  //Submit the for Repay 
 paymentForRepayment(param : any): Observable<any> {
  const get_url = baseApiUrl + '...' ;
    return this.http.post<any>(get_url,param,
      { headers: httpOptions.headers }).pipe(
        map(res => res 
          ),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }

  // /** Get data by Params */ changed
 getCustomerDataFromUserNameLink(uname:any){
    const get_url=baseApiUrl+'subscriber/getDetailsByUsername/'+uname;
    return this.http.get<any>(get_url);
  }

  getLiveUserQuota(userId):Observable<any> {
    const get_url = baseApiUrl + 'subscriber/getUsedQuota/'+userId;
    return this.http.get<any>(get_url,
      { headers: httpOptions.headers }).pipe(
        map(res => res),
        catchError((error: any) => {
          return throwError(error);
        })
      );
  }
}
